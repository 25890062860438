<template>
  <div  class="is-flex is-justify-content-center">
    <div>
      <vue3-chart-js
        :id="doughnutChart.id"
        :type="doughnutChart.type"
        :data="doughnutChart.data"
        :options="doughnutChart.options"
      >
      </vue3-chart-js>
    </div>
  </div>
</template>

<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
  components: {
    Vue3ChartJs,
  },

  props: {
    data: {
      type: Object
    },
  },

  setup(props) {

    const doughnutChart = {
      id: 'doughnut-spent',
      type: 'doughnut',
      data: props.data,
      options: {

        plugins: {
          responsive: true,
          legend: {
            position: 'bottom'
          }
        }
      }
    }


    return {
      doughnutChart
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
